<template>
  <div>
    <JobDetail />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JobDetail from "@/components/JobListDetail/jobListDetailMobile.vue";

export default {
  components: {
    JobDetail
  },
  created() {
        const {
      fullPath,
      params: { id }
    } = this.$route;
    this.urlFullPath = fullPath;
    this.jobBoardDetailAction({job_id: id, path: this.urlFullPath});
    this.scrollTop();
  },
  methods:{
    ...mapActions(['jobBoardDetailAction',"scrollTop"])
  }
};
</script>
