<template>
  <div class="job-board" :class="{ 'tfw-job-board': isSuperCustomer }">
    <CModal
      id="applyjobModal"
      title="NOTIFICATION"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
      >{{ this.modalMessage }}</CModal
    >
    <div class="text-right">
      <CButton color="primary" shape="pill" @click="backToJobBoard"
        >Back</CButton
      >
    </div>
    <div class="job-board-detail-component container-fluid">
      <div>
        <CRow class="d-flex justify-content-center">
          <img
            :src="fileSource.logo"
            class="rounded-circle ratio-1"
            style="width: 40%; height: 40%"
            @error="
              $event.target.style = 'width: 100px; height: 100px';
              $event.target.src = '/img/hospital.png';
            "
            alt="logo"
          />
          <span
            class="text-danger parent-info"
            v-if="
              isParentReBranding &&
              !isCandidateOrPublicBoard &&
              !isJobSupplierFromAccessToken
            "
            style="color: danger"
          >
            <em
              class="parent-branding-dot"
              v-c-tooltip="{
                content: 'Parent Branding Enabled',
                appendToBody: true,
              }"
            ></em>
          </span>
        </CRow>
      </div>
      <div class="title">
        <p>{{ organisation }}</p>
        <h6 class="text-primary mt-2">
          <i class="fas fa fa-map-marker-alt"></i>&nbsp;
          <span style="color: #00000099">
            <span v-if="displayLocation">{{ displayLocation }}</span>
            <span v-else>{{ location }}</span>
          </span>
        </h6>
      </div>
      <div class="title mt-3">
        <h5 class="text-center d-flex align-items-center">
          <a
            v-if="!isCandidateOrPublicBoard"
            :href="`/job-list-preview/${jobID}`"
            class="value text-primary"
            >{{ title }}</a
          >
          <span v-else class="value text-primary">{{ title }}</span>
          <span
            v-if="
              isAdmins &&
              ![Role.systemAdmin].includes(currentUserRole) &&
              !isJobSupplierFromAccessToken
            "
            @click="onCloneJob()"
            style="cursor: pointer"
            class="ml-2"
          >
            <em
              class="fas fa-copy"
              v-c-tooltip="{
                content: `Clone Job`,
                placement: `bottom`,
              }"
            ></em>
          </span>
          <span
            v-if="
              latestApprovalAudit &&
              isAdmins &&
              ![Role.systemAdmin].includes(currentUserRole)
            "
            @click="showApprovalComments()"
            style="cursor: pointer"
            class="ml-3"
          >
            <em
              class="fas fa-comments"
              v-c-tooltip="{
                content: `Comments`,
                placement: `bottom`,
              }"
            ></em>
          </span>
          <span v-if="isShowTFWPublish && isPublished && isAdmins" class="ml-2">
            <span
              v-if="publish_in_super_hospital"
              class="tfw-icon-green"
              v-c-tooltip="{
                content:
                  'This Job was published on the Talentfind World Public Job Board.',
                placement: 'left',
              }"
            ></span>
            <span
              v-else-if="isAdmins"
              class="tfw-icon cursor-pointer"
              v-c-tooltip="{
                content:
                  'Publish the job into Talentfind World Public Job Board',
                placement: 'left',
              }"
              @click="openTFWModal"
            ></span>
          </span>
          <span
            v-if="
              isAdmins &&
              ![Role.systemAdmin].includes(currentUserRole) &&
              getPendingStatus
            "
            :class="`status-${getPendingStatus.class}`"
            class="ml-3 title"
            style="white-space: nowrap"
          >
            <span>{{ getPendingStatus.content }}</span>
          </span>
        </h5>
      </div>
      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Candidate Type</div>
          <div class="value text-left">{{ candidateType }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label text-right">Candidate Level</div>
          <div
            class="value"
            v-if="isTextWrapped(candidateLevel, 50)"
            v-c-tooltip="{
              content: candidateLevel,
            }"
          >
            {{ candidateLevel.substring(0, 50) }}...
          </div>
          <div class="value" v-else>{{ candidateLevel }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Job Reference</div>
          <div v-if="!isCandidateOrPublicBoard" class="value text-left">
            <a
              :href="`/job-list-preview/${jobID}`"
              class="value"
              style="font-weight: 500"
              >{{ jobReference }}</a
            >
          </div>
          <div v-else class="value">
            <span class="value" style="font-weight: 500">
              {{ jobReference }}
            </span>
          </div>
        </div>
        <div class="detail-group-item">
          <div class="label text-right">Contract</div>
          <div class="value">{{ contract }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Date created</div>
          <div class="value text-left">{{ date_created }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label text-right">Expiry on</div>
          <div class="value">
            {{ expiryDate }} |
            <span style="font-weight: 500">{{ expiryDays }}</span>
          </div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-action" v-if="!isAdmins">
        <div class="detail-action-item">
          <span class="f-1_5 action" @click="favouriteJob(favouriteID)">
            <em
              :class="`${
                favouriteID !== null
                  ? 'text-primary fas fa-heart'
                  : 'far fa-heart'
              }`"
            ></em>
          </span>
        </div>
        <div class="detail-action-item text-left">
          <social-sharing
            class="share"
            v-if="true"
            :url="sharing.url"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitter-user="sharing.twitterUser"
            inline-template
          >
            <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <CIcon name="cil-share-alt" class="color-primary" />
                </div>
              </template>
              <CDropdownItem>
                <network network="email">
                  <em class="fa fa-envelope"></em> Email
                </network>
              </CDropdownItem>
              <div>
                <CDropdownItem>
                  <network network="facebook">
                    <em class="fab fa-facebook-f"></em> Facebook
                  </network>
                </CDropdownItem>
              </div>
            </CDropdown>
          </social-sharing>
        </div>
      </div>
      <div v-if="!isAdmins">
        <div class="action-group">
          <button
            v-if="!appliedID"
            type="button"
            name="JobApply"
            :disabled="
              disableJobApplyBtn &&
              jobReference == getSelectedJobBoard.display_uid
            "
            class="btn btn-primary btn-block mb-3"
            @click="onApplyJob()"
          >
            Apply Now
          </button>
          <button
            type="button"
            class="btn btn-secondary text-primary btn-block mb-3"
            v-else
            @click="onWithdrawJob()"
            :disabled="is_rejected"
          >
            Withdraw
          </button>
          <CBadge
            v-if="is_rejected && appliedID"
            color="danger"
            class="py-2 mb-3"
            >Application Unsuccessful</CBadge
          >
        </div>
      </div>

      <div v-else class="action-group">
        <div
          class="action-group"
          v-if="
            Roles.includes(currentUserRole) &&
            ![Role.systemAdmin].includes(currentUserRole)
          "
        >
          <button
            type="button"
            class="btn btn-primary mb-3"
            style="width: 100%"
            @click="onEditJob()"
            :disabled="isJobSupplierFromAccessToken"
          >
            Edit Job
          </button>
        </div>
        <social-sharing
          :url="url"
          :title="title"
          :description="jobReference"
          inline-template
        >
          <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-share-alt" class="color-primary" />
              </div>
            </template>
            <CDropdownItem>
              <network network="email">
                <em class="fa fa-envelope"></em> Email
              </network>
            </CDropdownItem>
            <div>
              <CDropdownItem>
                <network network="facebook">
                  <em class="fab fa-facebook-square"></em> Facebook
                </network>
              </CDropdownItem>
            </div>
          </CDropdown>
        </social-sharing>
      </div>
      <div
        v-if="isAdmins && ![Role.systemAdmin].includes(currentUserRole)"
        class="action-group"
      >
        <button
          v-if="isDraftOrPending && this.getSelectedJobBoard.is_private_job"
          type="button"
          class="btn btn-primary btn-block mb-3"
          @click="publishJob('direct_publish')"
          :disabled="isJobSupplierFromAccessToken"
        >
          Publish Job
        </button>
        <button
          v-if="isDraftOrPending && !this.getSelectedJobBoard.is_private_job"
          type="button"
          class="btn btn-primary btn-block mb-3"
          @click="publishJob('publish')"
          :disabled="isJobSupplierFromAccessToken"
        >
          {{ latestApprovalAudit ? "Republish Job" : "Publish Job" }}
        </button>
        <div
          class="detail-group mb-3"
          v-if="
            Roles.includes(currentUserRole) &&
            ![Role.systemAdmin].includes(currentUserRole) &&
            (isActiveJob || isDraftOrPending)
          "
        >
          <CDropdown
            style="width: -webkit-fill-available"
            placement="bottom-start"
          >
            <template #toggler>
              <button
                type="button"
                class="btn btn-primary btn-block"
                :disabled="isJobSupplierFromAccessToken"
              >
                Inactive / Archive
              </button>
            </template>
            <a
              v-if="isActiveJob || isDraftOrPending"
              @click="updateJobStatus('inactive')"
              class="dropdown-item"
              style="cursor: pointer"
            >
              Inactive
            </a>
            <a
              @click="updateJobStatus('archive')"
              class="dropdown-item"
              style="cursor: pointer"
            >
              Archive
            </a>
          </CDropdown>
        </div>
        <div
          class="action-group"
          v-if="
            Roles.includes(currentUserRole) &&
            ![Role.systemAdmin].includes(currentUserRole) &&
            isInActiveJob
          "
        >
          <button
            type="button"
            class="archive-button btn btn-primary mr-3 w-100"
            @click="updateJobStatus('archive')"
            :disabled="isJobSupplierFromAccessToken"
          >
            Archive
          </button>
        </div>
      </div>
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
          alt="Job Image"
        />
      </div>
      <div v-html="jobDescription"></div>
      <div
        class="pl-3 pr-3 mt-2"
        v-for="(item, index) in jobDocuments"
        :key="index"
      >
        For Additional Information
        <a @click="fileDownload(item)" class="cursor-pointer text-primary">
          {{ item.library_name || "Click here" }}({{ index + 1 }})
        </a>
      </div>
    </div>

    <CModal
      id="PublishJob"
      title="Publish Job"
      centered:false
      color="primary"
      :show.sync="publishModal"
    >
      <ValidationObserver
        ref="action_modal"
        v-slot="{ onPublish }"
        v-if="isSendForApprovalRequired"
      >
        <form id="action" @submit.prevent="onPublish()">
          <CRow class="m-2">
            <CCol md="6">
              <CRow class="row mb-3">
                <label class="required col-lg-12 col-md-12">
                  Alert Set For
                </label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <DatePicker
                      name="publish_request_expiry_date"
                      :value="approval_audit.publish_request_expiry_date"
                      @input="handleApprovalAudit"
                      :showFromTmw="true"
                      :error="errors[0]"
                      format="DD-MM-YYYY"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
            <CCol lg="6">
              <CRow class="mb-3">
                <label name="lbl_urgent" class="col-lg-12 col-md-12"
                  >Is Urgent?</label
                >
                <CCol md="12">
                  <RadioButton
                    name="publish_request_is_urgent"
                    :value="approval_audit.publish_request_is_urgent"
                    :options="
                      options && options['boolean'] ? options['boolean'] : []
                    "
                    @change="handleApprovalAudit"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="12" class="mb-3">
              <CRow class="row">
                <label class="required col-lg-12 col-md-12">Comments</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextareaInput
                      name="publish_request_comments"
                      :value="approval_audit.publish_request_comments"
                      @input="handleApprovalAudit"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
          </CRow>
        </form>
      </ValidationObserver>
      <p v-else>Do you want to Publish the selected job?</p>
      <template #footer-wrapper>
        <div class="d-flex justify-content-end px-4">
          <button @click="onCancelPublish" class="btn btn-secondary mb-3 mr-3">
            Cancel
          </button>
          <button
            v-if="isSendForApprovalRequired"
            @click="onPublish()"
            class="btn btn-primary mb-3"
            :disabled="!approval_audit.publish_request_comments"
          >
            {{ latestApprovalAudit ? "Republish Job" : "Publish Job" }}
          </button>
          <button v-else @click="onPublish()" class="btn btn-primary mb-3">
            Publish Job
          </button>
        </div>
      </template>
    </CModal>

    <CModal
      id="warningModel"
      title="NOTIFICATION"
      :color="warningModal.color"
      centered:false
      :show.sync="warningModal.isShowPopup"
    >
      <h6 class="text-center">{{ warningModal.content }}</h6>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <div v-if="!warningModal.on_click">
            <button
              @click="warningModal.isShowPopup = false"
              class="btn btn-danger"
            >
              Ok
            </button>
          </div>
          <div v-else>
            <button @click="clear()" class="btn btn-secondary mr-2">
              Cancel
            </button>
            <button
              v-if="warningModal.on_click == 'goToEditJob'"
              @click="goToEditJob()"
              class="btn btn-primary"
            >
              Confirm
            </button>
            <button
              v-if="warningModal.on_click == 'onShowCloneJob'"
              @click="onShowCloneJob()"
              class="btn btn-primary"
            >
              Confirm
            </button>
            <button
              v-if="warningModal.on_click == 'onUpdateJobStatus'"
              @click="onUpdateJobStatus()"
              class="btn btn-primary"
            >
              Confirm
            </button>
          </div>
        </footer>
      </template>
    </CModal>
    <job-approval-comments
      :isShowPopup="jobApprovalComments.isShowPopup"
      :approval_audits="jobApprovalComments.approval_audits"
      :popupModalCallBack="jobApprovalCommentsCallBack"
    />
    <PreviewPreScreening
      v-if="previewPreScreeningModal.isShowPopup"
      :isShowPopup="previewPreScreeningModal.isShowPopup"
      :jobInfo="previewPreScreeningModal.jobInfo"
      :preScreeningQuestions="previewPreScreeningModal.preScreeningQuestions"
      @modalCallBack="previewPreScreeningModalCallBack"
    />
    <Modal
      v-if="TFWPublishModal.isShowPopup"
      :modalTitle="TFWPublishModal.title"
      :modalColor="TFWPublishModal.color"
      :modalContent="TFWPublishModal.content"
      :isShowPopup="TFWPublishModal.isShowPopup"
      :buttons="TFWPublishModal.buttons"
      :modalCallBack="TFWPublishModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import jobListDetail from "@/components/JobListDetail/jobListDetail.vue";
import { mapActions } from "vuex";

export default {
  extends: jobListDetail,
  mounted() {
    this.scrollTop();
  },
  methods: {
    ...mapActions(["scrollTop"]),
    backToJobBoard() {
      this.$router.push(this.jobListBackUrl).then((res) => {
        this.$store.commit("REMOVE_JOB_LIST_BACK_URL", this.jobListBackUrl);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.status-awaiting {
  background-color: green;
  border: 1px solid green;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-onhold {
  background-color: orange;
  border: 1px solid orange;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-disapproved {
  background-color: red;
  border: 1px solid red;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.ratio-1 {
  aspect-ratio: 1;
}
.parent-info {
  position: relative;
  margin-top: 1.05rem;
}
.parent-branding-dot {
  height: 10px;
  width: 10px;
  background-color: $font-theme-color;
  border-radius: 60%;
  display: inline-block;
  position: absolute;
  z-index: 1000;
  top: -5px;
  right: 3px;
}
</style>
